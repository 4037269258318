<template>
	<b-card>
		<b-row class="m-1 top-header">
			<b-col cols="12" md="6" class="d-flex align-items-center">
				<h3 class="mb-1">Hatchery</h3>
			</b-col>
		</b-row>
		<div v-if="userHatchery.length > 0">
			<div
				class="list-view m-1"
				v-for="hatchery in userHatchery"
				v-bind:key="hatchery.id"
				v-on:click="settingView(hatchery.id)"
			>
				<b-row>
					<b-col md="3">
						<div class="d-flex">
							<b-img
								:src="require('@/assets/images/svg/machine/hatchery.svg')"
								style="margin-right: 10px"
							/>
							<div class="head-text">
								<!-- {{ hatchery.id }} -->
								{{ hatchery.hatchery_name }}
								<h5>{{ hatchery.address }}</h5>
							</div>
						</div>
					</b-col>
					<b-col class="d-flex"
						><div class="d-flex align-items-center"></div>
					</b-col>
					<!-- <b-col md="3" class="d-flex justify-content-between">
          <div class="head-text"><span class="bullet"></span></div>

        </b-col> -->
					<b-col md="1" class="d-flex align-items-center justify-content-end">
						<div class="mr-3">
							<feather-icon icon="SettingsIcon" size="18" />
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img
				:src="require('@/assets/images/svg/hatchery/no-hatchery.svg')"
				class="mr-2"
			/>
			<h4 class="mt-2 Secondary">There is No list of Hatchery</h4>
		</div>
	</b-card>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BImg,
} from "bootstrap-vue";

import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,
		BImg,
	},
	data() {
		return {
			DeviceId: null,
			hatchery_name: null,
			userHatchery: [],
		};
	},
	created: function () {
		this.getHatcheryList();
	},
	methods: {
		moment() {
			return moment();
		},

		getHatcheryList() {
			const hatcheryId = this.$route.params.hatcheryId;
			axiosIns
				.get(`web/hatchery/${hatcheryId}/hatchery-setting`)
				.then((response) => {
					this.userHatchery = response.data.userHatchery;
				})
				.catch((error) => {
					error;
				});
		},
		settingView() {
			this.$router.push({ name: "apps-settings" });
		},
	},
};
</script>

<style lang="scss" scoped>
.list-view {
	background-color: #fafafa;
	box-shadow: 0px 3px 6px #38c06c33;

	padding: 1rem 1rem;
	margin: auto;
}
.head-text {
	font-size: 18px;
	color: #1d1d1d;
}

.svg-img {
	width: 17px;
	height: 17px;
	// margin-right: 10px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
